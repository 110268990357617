<template>
  <v-tabs
    v-model="tab"
    vertical
    background-color="red lighten-5"
  >
    <v-tab href="#wk">Wettkampfdaten</v-tab>
    <v-tab href="#geraete">Geräte</v-tab>
    <v-tab href="#riegen">Riegen</v-tab>
    <v-tab href="#kari">Kampfgerichte</v-tab>
    <v-tab href="#status">Wettkampfstatus</v-tab>
    <v-tab v-if="isMaster" href="#wkacl">Wettkampfverantwortliche</v-tab>

    <v-tab-item value="wk"><wettkampfdaten :id="id" :e="e" :df="df" /></v-tab-item>
    <v-tab-item value="geraete"><geraete :id="id" :e="e" :df="df" /></v-tab-item>
    <v-tab-item value="riegen"><riegen :id="id" :e="e" :df="df" /></v-tab-item>
    <v-tab-item value="kari"><kampfgerichte :id="id" :e="e" :df="df" /></v-tab-item>
    <v-tab-item value="status"><status :id="id" :e="e" :r="r" :df="df" /></v-tab-item>
    <v-tab-item value="wkacl"><wkacl :id="id" :e="e" /></v-tab-item>
  </v-tabs>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'allgemein',

  components: {
    Wettkampfdaten: () => import('./allgemein/wettkampfdaten'),
    Geraete: () => import('./allgemein/geraete'),
    Riegen: () => import('./allgemein/riegen'),
    Kampfgerichte: () => import('./allgemein/kampfgerichte'),
    Status: () => import('./allgemein/status'),
    Wkacl: () => import('./allgemein/wkacl')
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    tab: 'wk'
  }),

  computed: {
    ...mapGetters(['isMaster'])
  }
}
</script>

<style scoped>

</style>
